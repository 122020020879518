import { createRouter, createWebHistory } from 'vue-router'
import Home from "../views/Home.vue";
import Faq from "../views/Faq.vue";
import Pricing from "../views/Pricing.vue";
import ToS from "../views/ToS.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import NotFound from "../views/NotFound.vue";
import { META } from "@/constants/meta";

export const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "PacificES Cloud • Low Latency CS:GO CS2 Server Hosting",
      metaTags: [
        ...META,
        {
          name: "description",
          content: "Get the best CS:GO & CS2 server hosting from PacificES. Instant on, low ping, high tickrate servers. Host your own CSGO/CS2 server today.",
        },
        {
          property: "og:title",
          content: "PacificES Cloud • Low Latency CS:GO CS2 Server Hosting",
        },
        {
          property: "og:description",
          content: "Get the best CS:GO & CS2 server hosting from PacificES. Instant on, low ping, high tickrate servers. Host your own CSGO/CS2 server today.",
        },
        {
          property: "og:url",
          content: "https://pacifices.cloud/",
        },
        {
          property: "twitter:title",
          content: "PacificES Cloud • Low Latency CS:GO CS2 Server Hosting",
        },
        {
          property: "twitter:description",
          content: "Get the best CS:GO & CS2 server hosting from PacificES. Instant on, low ping, high tickrate servers. Host your own CSGO/CS2 server today.",
        },
      ],
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: Faq,
    meta: {
      title: "Frequently Asked Questions (FAQs) • PacificES Cloud",
      metaTags: [
        ...META,
        {
          name: "description",
          content:
            "Find answers to frequently asked questions about PacificES Cloud. Learn about our CS:GO & CS2 server hosting, panel access, billing, and more for players in the Asia-Pacific.",
        },
        {
          property: "og:title",
          content: "Frequently Asked Questions (FAQs) • PacificES Cloud",
        },
        {
          property: "og:description",
          content:
            "Find answers to frequently asked questions about PacificES Cloud. Learn about our CS:GO & CS2 server hosting, panel access, billing, and more for players in the Asia-Pacific.",
        },
        {
          property: "og:url",
          content: "https://pacifices.cloud/faq",
        },
        {
          property: "twitter:title",
          content: "Frequently Asked Questions (FAQs) • PacificES Cloud",
        },
        {
          property: "twitter:description",
          content:
            "Find answers to frequently asked questions about PacificES Cloud. Learn about our CS:GO & CS2 server hosting, panel access, billing, and more for players in the Asia-Pacific.",
        },
      ],
    },
  },
  {
    path: "/pricing",
    name: "pricing",
    component: Pricing,
    meta: {
      title: "AUD $0.95/day CS:GO & CS2 Servers • Pricing • PacificES Cloud",
      metaTags: [
        ...META,
        {
          name: "description",
          content:
            "Straightforward and affordable pricing for CS:GO & CS2 server hosting. Just $0.95 AUD/day for high-tick, fully-featured servers in the Asia-Pacific region.",
        },
        {
          property: "og:title",
          content: "AUD $0.95/day CS:GO & CS2 Servers • Pricing • PacificES Cloud",
        },
        {
          property: "og:description",
          content:
            "Straightforward and affordable pricing for CS:GO & CS2 server hosting. Just $0.95 AUD/day for high-tick, fully-featured servers in the Asia-Pacific region.",
        },
        {
          property: "og:url",
          content: "https://pacifices.cloud/pricing",
        },
        {
          property: "twitter:title",
          content: "AUD $0.95/day CS:GO & CS2 Servers • Pricing • PacificES Cloud",
        },
        {
          property: "twitter:description",
          content:
            "Straightforward and affordable pricing for CS:GO & CS2 server hosting. Just $0.95 AUD/day for high-tick, fully-featured servers in the Asia-Pacific region.",
        },
      ],
    },
  },
  {
    path: "/tos",
    name: "ToS",
    component: ToS,
    meta: {
      title: "Terms of Service (ToS) • PacificES Cloud",
      metaTags: [
        ...META,
        {
          name: "description",
          content: "Terms of Service for PacificES Cloud — usage, availability, payment, and rules of use for your servers.",
        },
        {
          property: "og:title",
          content: "Terms of Service (ToS) • PacificES Cloud",
        },
        {
          property: "og:description",
          content: "Terms of Service for PacificES Cloud — usage, availability, payment, and rules of use for your servers.",
        },
        {
          property: "og:url",
          content: "https://pacifices.cloud/tos",
        },
        {
          property: "twitter:title",
          content: "Terms of Service (ToS) • PacificES Cloud",
        },
        {
          property: "twitter:description",
          content: "Terms of Service for PacificES Cloud — usage, availability, payment, and rules of use for your servers.",
        },
      ],
    },
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      title: "Privacy Policy • PacificES Cloud",
      metaTags: [
        ...META,
        {
          name: "description",
          content: "This following document sets forth the Privacy Policy for the PacificES Cloud website, cloud.pacifices.net",
        },
        {
          property: "og:title",
          content: "Privacy Policy • PacificES Cloud",
        },
        {
          property: "og:url",
          content: "https://pacifices.cloud/privacypolicy",
        },
        {
          property: "og:description",
          content: "This following document sets forth the Privacy Policy for the PacificES Cloud website, cloud.pacifices.net",
        },
        {
          property: "twitter:title",
          content: "Privacy Policy • PacificES Cloud",
        },
        {
          property: "twitter:description",
          content: "This following document sets forth the Privacy Policy for the PacificES Cloud website, cloud.pacifices.net",
        },
      ],
    },
  },
  // will match everything and put it under `$route.params.pathMatch`
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// This callback runs before every route change
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if it has a parent/child route, it uses the child route's title.
  to.matched
    .slice()
    .reverse()
    .forEach((matched) => {
      if (matched.meta.title) {
        document.title = matched.meta.title;
      }

      if (matched.meta.metaTags) {
        matched.meta.metaTags.forEach((tag) => {
          const tagElement = document.createElement("meta");
          Object.keys(tag).forEach((key) => {
            tagElement.setAttribute(key, tag[key]);
          });
          document.head.appendChild(tagElement);
        });
      }
    });

  next();
});

export default router
