import { createApp } from "vue";
import { createMetaManager } from "vue-meta";
import App from "./App.vue";
import router from "./router";
import "./assets/css/global.css";
import "./assets/css/output.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowRightArrowLeft, faGaugeHigh } from "@fortawesome/free-solid-svg-icons";
library.add({ faGaugeHigh, faArrowRightArrowLeft });

const app = createApp(App);
app.use(router);
app.use(createMetaManager());
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
