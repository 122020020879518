<template>
  <div>
    <nav-component />
    <div class="home-hero">
      <div class="container" id="container">
        <div class="row">
          <div class="col-md-6 text-center home-hero-text">
            <h1>CS2 & CS:GO Cloud Servers</h1>
            <p>As low as $0.95 AUD a day</p>
            <br />
            <a type="button" class="btn btn-default" href="https://panel.pacifices.cloud/login/openid?login">
              <font-awesome-icon :icon="['fab', 'steam']" /> Login with Steam
            </a>
            <br />
          </div>
          <div class="col-md-6 create-server-video">
            <div class="browser">
              <div class="crossbar">
                <ul class="dots">
                  <li class="red"></li>
                  <li class="yellow"></li>
                  <li class="green"></li>
                </ul>
              </div>
              <video width="100" autoplay loop muted class="video" ref="video">
                <source src="../assets/img/howTo.webm" type="video/webm" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="home-sub-hero">
      <div>
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <img
                v-b-tooltip.hover
                title="Guam, Pacific Ocean"
                src="../assets/img/flags/gu.png"
                alt="Guam, Pacific Ocean"
                data-container="body"
                data-placement="bottom"
                data-content="Guam, Pacific Ocean"
              />
              <img
                v-b-tooltip.hover
                title="Sydney, Australia"
                src="../assets/img/flags/au.png"
                alt="Sydney, Australia"
                data-container="body"
                data-placement="bottom"
                data-content="Sydney, Australia"
              />
              <img
                v-b-tooltip.hover
                title="Hong Kong, Hong Kong"
                src="../assets/img/flags/hk.png"
                alt="Hong Kong, Hong Kong"
                data-container="body"
                data-placement="bottom"
                data-content="Hong Kong, Hong Kong"
              />
              <img
                v-b-tooltip.hover
                title="Tokyo, Japan"
                src="../assets/img/flags/jp.png"
                alt="Tokyo, Japan"
                data-container="body"
                data-placement="bottom"
                data-content="Tokyo, Japan"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="flex my-12 flex-col md:flex-row">
        <div class="col-md-6">
          <div class="home-features-listings">
            <div class="feature-row">
              <div class="col-md-1"></div>
              <div class="col-md-10 feature-listing">
                <div class="feature-item">
                  <div class="col-md-3">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div class="col-md-9">
                    <h3>Performance Nodes</h3>
                    <p>All servers deployed are hosted on high quality servers designed for performance</p>
                  </div>
                </div>
              </div>
              <div class="col-md-1"></div>
            </div>
            <div class="feature-row">
              <div class="col-md-1"></div>
              <div class="col-md-10 feature-listing">
                <div class="feature-item">
                  <div class="col-md-3">
                    <i class="fas fa-database"></i>
                  </div>
                  <div class="col-md-9">
                    <h3>Enterprise Infrastructure</h3>
                    <p>Using infrastructure and networks designed for enterprise, ensures "always up" servers</p>
                  </div>
                </div>
              </div>
              <div class="col-md-1"></div>
            </div>
            <div class="feature-row">
              <div class="col-md-1"></div>
              <div class="col-md-10 feature-listing">
                <div class="feature-item">
                  <div class="col-md-3">
                    <i class="fas fa-globe"></i>
                  </div>
                  <div class="col-md-9">
                    <h3>Low-latency Network</h3>
                    <p>Our CSGO and CS2 servers are delivered over the low-latency OneQode network — built for gaming</p>
                  </div>
                </div>
              </div>
              <div class="col-md-1"></div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="home-features-listings">
            <div class="feature-row">
              <div class="col-md-1"></div>
              <div class="col-md-10 feature-listing">
                <div class="feature-item">
                  <div class="col-md-3">
                    <i class="fas fa-terminal" style="width: 56px"></i>
                  </div>
                  <div class="col-md-9">
                    <h3>Full Console Access</h3>
                    <p>Full console access allows you to execute any Counter-Strike commands</p>
                  </div>
                </div>
              </div>
              <div class="col-md-1"></div>
            </div>
            <div class="feature-row">
              <div class="col-md-1"></div>
              <div class="col-md-10 feature-listing">
                <div class="feature-item">
                  <div class="col-md-3">
                    <font-awesome-icon :icon="['fass', 'arrow-right-arrow-left']"></font-awesome-icon>
                  </div>
                  <div class="col-md-9">
                    <h3>Realtime Deployment</h3>
                    <p>With our Realtime Deployment features, servers can be up and running in 2 seconds</p>
                  </div>
                </div>
              </div>
              <div class="col-md-1"></div>
            </div>
            <div class="feature-row">
              <div class="col-md-1"></div>
              <div class="col-md-10 feature-listing">
                <div class="feature-item">
                  <div class="col-md-3">
                    <font-awesome-icon :icon="['fas', 'gauge-high']" style="max-width: 64px" />
                  </div>
                  <div class="col-md-9">
                    <h3>Custom Dashboard</h3>
                    <p>The Custom Dashboard is designed for optimised management of your server or servers!</p>
                  </div>
                </div>
              </div>
              <div class="col-md-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-compotent />
  </div>
</template>

<script>
import fontawesome from "@fortawesome/fontawesome";
import solid from "@fortawesome/fontawesome-free-solid";
import brands from "@fortawesome/fontawesome-free-brands";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import FooterCompotent from "./FooterCompotent.vue";
import NavComponent from "./NavComponent.vue";
fontawesome.library.add(brands, solid);

export default {
  name: "Home",
  components: {
    FontAwesomeIcon,
    FooterCompotent,
    NavComponent,
  },
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.home-hero {
  width: 100%;
  min-height: 300px;
  padding: 50px 0;
  background: #0e57a3; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(#0e57a3, #1378e2); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(#0e57a3, #1378e2); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(#0e57a3, #1378e2); /* For Firefox 3.6 to 15 */
  background: linear-gradient(#0e57a3, #1378e2); /* Standard syntax */
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.home-hero h1 {
  margin-top: 150px;
}
.home-hero .home-hero-icons {
  text-align: center;
  margin-top: 75px;
}
.home-hero .home-hero-icons .home-hero-icon {
  width: 150px;
  display: inline-block;
  margin-bottom: 20px;
}
.home-hero .home-hero-icons .home-hero-icon i {
  font-size: 40px;
  color: #fff;
}
.home-hero .home-hero-icons .home-hero-icon h4 {
  color: #fff;
}
.home-hero .home-hero-text {
  width: 100%;
  align-items: center;
  text-align: center;
  color: #fff;
}
.home-hero .home-hero-text h1 {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.1;
}

.home-features-listings {
  /* margin: 50px 0; */
}
.home-features-listings .feature-row {
  margin-bottom: 20px;
}

.feature-item {
  /* float: left; */
  display: flex;
}
.feature-item .col-md-3 {
  /* float: left; */
  padding-left: 15px;
  padding-right: 15px;
  width: 25%;
}

.home-features-listings .feature-listing {
  /* float: left; */
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
}
.home-features-listings .feature-listing svg {
  /* float: left; */
  font-size: 64px;
  background: linear-gradient(#0e57a3, #1378e2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.home-features-listings .feature-listing h3 {
  margin-top: 5px;
}
.home-features-listings .feature-listing p {
  max-width: 225px;
}
.home-features-listings .feature-listing.active {
  background-color: #0e57a3;
}
.home-features-listings .feature-listing.active i,
.home-features-listings .feature-listing.active h3,
.home-features-listings .feature-listing.active p {
  color: #fff;
}
.create-server-video {
  padding-left: 10%;
}
.create-server-video .home-features-listings {
  margin: 40px 0;
}
.create-server-video .browser {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 0;
  background-color: #fff;
}
.create-server-video .browser video {
  width: 100%;
}
.create-server-video .browser .crossbar {
  border-bottom: 1px solid #ccc;
  padding: 7px 11px;
  font-size: 14px;
}
.create-server-video .browser .crossbar .dots {
  margin: 0;
  padding: 0;
  list-style: none;
}
.create-server-video .browser .crossbar .dots .red {
  background-color: #f44336;
}
.create-server-video .browser .crossbar .dots .yellow {
  background-color: #ffeb3b;
}
.create-server-video .browser .crossbar .dots .green {
  background-color: #4caf50;
}
.create-server-video .browser .crossbar .dots li {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  margin-right: 5px;
}

.row {
  /* width: 50%; */
  position: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin-left: 12%;
  margin-right: 12%; */
}

.home-sub-hero {
  text-decoration: none;
}
.home-sub-hero > div {
  padding: 10px 0;
  background-color: #f4f6f8;
  color: #333;
}
.home-sub-hero:hover,
.home-sub-hero:focus {
  text-decoration: none;
}
.home-sub-hero h4 {
  display: inline-block;
  color: #333;
}
.home-sub-hero img {
  height: 30px;
  margin-left: 10px;
}

.home-hero {
  color: #fff;
}

.btn-default {
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  background-color: #333;
  border-color: #333;
  color: #fff;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active {
  color: #fff !important;
  background-color: #555 !important;
  border-color: #555 !important;
}

.btn {
  outline: none !important;
}
.btn:hover,
.btn:active,
.btn:focus,
.btn.active {
  box-shadow: none;
  outline: none !important;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .row {
    flex-direction: column;
  }
  .create-server-video {
    padding-top: 10%;
    padding-left: 0;
    padding-right: 0;
  }

  .text-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .text-center img {
    border-radius: 40px;
  }

  .home-hero-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
