<template>
  <nav-component />
  <div class="header-holder">
    <h1 class="title">Pricing</h1>
  </div>

  <div class="container max-w-screen-xl">
    <div class="pricing-holder">
      <div class="row flex-wrap gap-12 justify-center">
        <div class="w-full max-w-80">
          <div class="panel panel-default pricing-panel">
            <div class="panel-heading">
              <h2>$0.95<span> AUD a day</span></h2>
            </div>
            <div class="panel-heading panel-heading-secondary">
              <h4>1 CS Cloud Server</h4>
            </div>
            <div class="panel-body">
              <h3><i class="fa fa-check"></i> 128 Tick</h3>
              <h3><i class="fa fa-check"></i> 12 Slots</h3>
              <h3><i class="fa fa-check"></i> Warmod</h3>
              <h3><i class="fa fa-check"></i> Demo Recording</h3>
              <h3><i class="fa fa-check"></i> Asia-Pacific & OCE regions</h3>
              <h3><i class="fa fa-check"></i> All gamemodes</h3>
              <h3><i class="fa fa-check"></i> All Official Maps</h3>
            </div>
          </div>
        </div>
        <div class="w-full max-w-2xl px-4">
          <h3 class="title">Pricing FAQ</h3>
          <br />
          <div class="row flex-wrap gap-8 justify-center">
            <div class="max-w-xs">
              <h4>How am I billed?</h4>
              <p>Each server is billed $0.95 AUD a day.</p>
              <br />
              <h4>Is there a minimum amount of time my server can run for?</h4>
              <p>Yes, you will be charged for 1 day ($0.95 AUD) of usage if you start a server only for a few minutes.</p>
              <br />
              <h4>Will I be billed whilst the server is off?</h4>
              <p>No - your IP and Port for the server will be lost however, and when you start the server up you will be issued with a new one.</p>
            </div>
            <div class="max-w-xs">
              <h4>Am I able to start multiple servers?</h4>
              <p>Yes, you may start multiple servers - however, your servers will halt if you do not have enough credit to sustain their use.</p>
              <br />
              <h4>What forms of payment do you accept?</h4>
              <p>We accept Credit Cards through PayPal.</p>
              <br />
              <h4>Can I get a refund?</h4>
              <p>No, we do not provide refunds.</p>
            </div>
          </div>
          <br />
          <a href="faq">More questions can be found at the FAQ.</a>
        </div>
      </div>
    </div>
  </div>
  <footer-compotent />
</template>

<script>
import fontawesome from "@fortawesome/fontawesome";
import solid from "@fortawesome/fontawesome-free-solid";
import brands from "@fortawesome/fontawesome-free-brands";
import FooterCompotent from "./FooterCompotent.vue";
import NavComponent from "./NavComponent.vue";
fontawesome.library.add(brands, solid);

export default {
  name: "Pricing",
  components: {
    FooterCompotent,
    NavComponent,
  },
};
</script>

<style scoped>
.row {
  /* position: center; */
  display: flex;
  /* justify-content: center; */
  /* margin-left: -15px;
  margin-right: -15px; */
}
.pricing-holder {
  /* margin: 50px; */
}

.col-md-5 {
  width: 41.66%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-6 {
  /* width: 58%;
  float: left; */
  padding-left: 15px;
  padding-right: 15px;
}

.col-md-6 h4 {
  font-family: inherit;
  font-weight: 700;
  line-height: 1.1;
  color: inherit;
  font-size: 18px;
}
.col-md-7 {
  width: 58%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
}
.pricing-holder {
  margin: 50px 0;
}
.pricing-holder .title {
  text-align: center;
}
.pricing-holder .price-holder {
  text-align: center;
}
.pricing-holder .pricing-panel {
  margin: auto;
  border: none;
}
.pricing-holder .pricing-panel .panel-heading {
  border: none;
  text-align: center;
}
.pricing-holder .pricing-panel .panel-heading-secondary {
  background: #0e57a3; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(#0e57a3, #1378e2); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(#0e57a3, #1378e2); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(#0e57a3, #1378e2); /* For Firefox 3.6 to 15 */
  background: linear-gradient(#0e57a3, #1378e2); /* Standard syntax */
  border-radius: 0;
}
.pricing-holder .pricing-panel .panel-heading-secondary h4 {
  color: #fff;
}
.pricing-holder .pricing-panel .panel-body {
  padding: 15px 30px;
}
.pricing-holder .pricing-panel .panel-body h3 {
  margin: 10px;
}
.pricing-holder .pricing-panel .panel-body i {
  color: #555;
}

.panel-body h3 {
  font-size: 24px;
  font-weight: 300;
}
.panel-body h4 {
  font-size: 24px;
  font-weight: 300;
}
.h2,
h2 {
  font-size: 30px;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-weight: 700;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 18px;
  line-height: 1.1;
}
.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}
</style>
