const image = "https://pacifices.cloud/img/pacifices-cloud-opengraph-preview-image.png";

export const META = [
  {
    property: "og:type",
    content: "website",
  },
  {
    property: "og:site_name",
    content: "PacificES Cloud",
  },
  {
    property: "og:image",
    content: image,
  },
  {
    property: "article:publisher",
    content: "https://www.facebook.com/PacificES.net/",
  },
  {
    property: "twitter:card",
    content: "summary_large_image",
  },
  {
    property: "twitter:image",
    content: image,
  },
  {
    property: "twitter:site",
    content: "@PacificESGaming",
  },
  {
    property: "twitter:creator",
    content: "@PacificESGaming",
  },
];
