<template>
  <nav-component />
  <div class="header-holder">
    <h1 class="title">Terms of Service</h1>
  </div>

  <div class="container max-w-screen-md">
    <div class="pricing-holder p-4">
      <div class="row">
        <div>
          <h4>
            This document outlines the Terms of Service ("Terms", "Terms of Service") of use of the PacificES Cloud (the "Provider") website. Before using any
            PacificES Cloud services (the "Service") you (the "User") are required to read, understand and agree to these terms.
          </h4>
          <br />
          <h4>These Terms apply to all visitors, users and other who access or use the Service.</h4>

          <br />
          <h3>Eligibility</h3>
          <br />
          <p>
            The Service is not targeted towards nor intended for use by anyone under the age of 15. As the
            <a
              href="http://www.classification.gov.au/Pages/View.aspx?sid=BaQftqN4tOF6%252fqBXWmXs9Q%253d%253d&ncdctx=CmqKNzH9BDfRA8fGbSIqNGDUDnDA0MF9t51IEINzW%252fX4U%252fB7FAcWoJhVD2u4fSi0"
              target="_blank"
              >Australian Classification of Counter-Strike: Global Offensive is MA 15+</a
            >, we have abided by this classification and reserve the right to terminate anyone who does not meet the minimum age of 15 years old.
          </p>
          <p>
            To use the Service, you must sign up using a Steam account. You will also need to provide a valid and active email. You may not provide an invalid
            or inactive email. Failure to provide an email will not allow you to access the Service.
          </p>

          <br />
          <h3>Description of Service</h3>
          <br />
          <p>
            The Service is a hosting platform and software for the creation, running and management of Counter-Strike: Global Offensive servers. The Service
            provides tools and content for making administering a Counter-Strike: Global Offensive easier.
          </p>
          <p>
            The Service is provided 'as is' and any support provided outside the stock-standard Service is complementary and was never a requirement of the
            Service.
          </p>

          <br />
          <h3>Modification of Service</h3>
          <br />
          <p>The Provider allows for some modification of the base Service through the use of custom Configs, Plugins and Maps.</p>
          <p>The Provider will not be held accountable for reduced performance as a result of these modifications.</p>
          <p>The Provider reserves the right to charge an increased hourly rate for the modification of the Service.</p>
          <p>The Provider will provide little to no support when performance is reduced by modifications of the Service.</p>
          <p>The Provider will provide little to no support when the User is modifying the Service.</p>

          <br />
          <h3>Payment and Account Credit</h3>
          <br />
          <p>The User must provide a valid payment method to add credit to the User's account.</p>
          <p>The Provider reserves the right to cancel any payment.</p>
          <p>The Provider reserves the right to terminate any server if the User's account does not have enough funds to sustain the use of the server.</p>

          <br />
          <h3>Termination</h3>
          <br />
          <p>The Provider reserves the right to discontinue the Service at any time, with or without a refund.</p>

          <br />
          <h3>Availability</h3>
          <br />
          <p>The Provider reserves the right at any time to make the Service temporarily unavailable for maintenance or for other reasons.</p>
          <p>The Provider is not obligated to notify Users when maintenance or other service disruptions occur.</p>
          <p>The Provider will not be liable for any monetary losses or compensation as a result of notified or otherwise unavailability of the service.</p>

          <br />
          <h3>Rules of Use</h3>
          <br />
          <p>
            The User shall not use the Service provided by the Provider for malicious uses, illegal activity or immoral (at the discretion of the Provider)
            operations and your access to the Service may be terminated.
          </p>
          <p>
            The User shall not modify or change any restricted settings or server variables (at the discretion of the Provider) and your access to the Service
            may be terminated.
          </p>
          <p>
            The Provider reserves the right to terminate a User's account if they are suspected to be operating a Denial of Service attack or other malicious
            activity against the Service or the Provider.
          </p>

          <br />
          <h4>The Provider reserves the right to change or modify the Terms with or without prior notice.</h4>
        </div>
      </div>
    </div>
  </div>

  <footer-compotent />
</template>

<script>
import fontawesome from "@fortawesome/fontawesome";
import solid from "@fortawesome/fontawesome-free-solid";
import brands from "@fortawesome/fontawesome-free-brands";
import FooterCompotent from "./FooterCompotent.vue";
import NavComponent from "./NavComponent.vue";
import { defineComponent } from "vue";
fontawesome.library.add(brands, solid);

export default defineComponent({
  name: "ToS",
  components: {
    FooterCompotent,
    NavComponent,
  },
})
</script>

<style scoped>
.row {
  position: center;
  display: flex;
  justify-content: center;
  /* margin-left: -15px;
  margin-right: -15px; */
}

p {
  margin: 0 0 10px;
  font-size: 14px;
}
.col-md-2 {
  width: 16.6%;
}
.col-md-8 {
  width: 66.6%;
}
.h3,
h3 {
  font-size: 24px;
}
</style>
