<template>
  <nav-component />
  <div class="header-holder">
    <h1 class="title">Privacy Policy</h1>
  </div>

  <div class="container max-w-screen-md">
    <div class="pricing-holder p-4">
      <div class="row">
        <div>
          <h4>This following document sets forth the Privacy Policy for the PacificES Cloud website, cloud.pacifices.net.</h4>
          <h4>
            PacificES Cloud is committed to providing you with the best possible customer service experience. PacificES Cloud is bound by the Privacy Act 1988
            (Crh), which sets out a number of principles concerning the privacy of individuals.
          </h4>
          <br />

          <h3>Collection of your personal information</h3>
          <br />
          <p>
            There are many aspects of the site which can be viewed without providing personal information, however, for access to future PacificES Cloud
            customer support features you are required to submit personally identifiable information. This may include but not limited to a unique username and
            password, or provide sensitive information in the recovery of your lost password.
          </p>

          <h3>Sharing of your personal information</h3>
          <br />
          <p>
            We may occasionally hire other companies to provide services on our behalf, including but not limited to handling customer support enquiries,
            processing transactions or customer freight shipping. Those companies will be permitted to obtain only the personal information they need to deliver
            the service. PacificES Cloud takes reasonable steps to ensure that these organisations are bound by confidentiality and privacy obligations in
            relation to the protection of your personal information.
          </p>

          <h3>Use of your personal information</h3>
          <br />
          <p>
            For each visitor to reach the site, we expressively collect the following non-personally identifiable information, including but not limited to
            browser type, version and language, operating system, pages viewed while browsing the Site, page access times and referring website address. This
            collected information is used solely internally for the purpose of gauging visitor traffic, trends and delivering personalized content to you while
            you are at this Site.
          </p>
          <p>
            From time to time, we may use customer information for new, unanticipated uses not previously disclosed in our privacy notice. If our information
            practices change at some time in the future we will use for these new purposes only, data collected from the time of the policy change forward will
            adhere to our updated practices.
          </p>

          <h3>Changes to this Privacy Policy</h3>
          <br />
          <p>
            PacificES Cloud reserves the right to make amendments to this Privacy Policy at any time. If you have objections to the Privacy Policy, you should
            not access or use the Site.
          </p>

          <h3>Accessing Your Personal Information</h3>
          <br />
          <p>
            You have a right to access your personal information, subject to exceptions allowed by law. If you would like to do so, please let us know. You may
            be required to put your request in writing for security reasons. PacificES Cloud reserves the right to charge a fee for searching for, and providing
            access to, your information on a per request basis.
          </p>
        </div>
      </div>
    </div>
  </div>

  <footer-compotent />
</template>

<script>
import fontawesome from "@fortawesome/fontawesome";
import solid from "@fortawesome/fontawesome-free-solid";
import brands from "@fortawesome/fontawesome-free-brands";
import FooterCompotent from "./FooterCompotent.vue";
import NavComponent from "./NavComponent.vue";
fontawesome.library.add(brands, solid);

export default {
  name: "PrivacyPolicy",
  components: {
    FooterCompotent,
    NavComponent,
  },
};
</script>

<style scoped>
.row {
  position: center;
  display: flex;
  justify-content: center;
  /* margin-left: -15px;
  margin-right: -15px; */
}

.pricing-holder {
  /* margin: 50px 0; */
}

.h4,
h4 {
  font-size: 18px;
}

.col-md-2 {
  width: 16.6%;
}

.col-md-8 {
  width: 66.6%;
}
</style>
