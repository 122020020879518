<template>
  <div class="nav">
    <div class="container">
      <div class="row justify-between px-4 lg:px-0 pt-4 lg:pt-0">
        <div class="flex items-center">
          <router-link to="/">
            <img class="h-12 w-auto" src="../assets/img/pacifices-logo.svg" alt="PacificES Logo" />
          </router-link>
        </div>
        <div class="nav-links flex items-center justify-between gap-8">
          <div class="flex gap-3">
            <router-link to="/pricing"><h4>Pricing</h4></router-link>
            <router-link to="/faq"><h4>FAQ</h4></router-link>
            <a href="https://api.pacifices.cloud/docs.html" target="_blank"><h4>API</h4></a>
          </div>
          <div class="login-steam">
            <a type="button" class="btn btn-default whitespace-nowrap" href="https://panel.pacifices.cloud/login/openid?login">
              <font-awesome-icon :icon="['fab', 'steam']" /> Login with Steam
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 1170px;
}
.nav {
  display: flex;
  background-color: #fff;
  min-height: 90px;
}
.nav .nav-logo img {
  height: 50px;
  margin: 20px 0;
}
.nav .nav-logo a {
  text-decoration: none;
}
.nav .nav-logo a:hover {
  text-decoration: none;
}
.nav .nav-logo h3 {
  color: #fff;
  margin-bottom: 20px;
  display: inline-block;
}
.nav .nav-links {
  text-align: right;
}
.nav .nav-links h4 {
  display: inline-block;
  margin: 35px 0;
  color: #333;
}
.nav .nav-links a {
  text-decoration: none;
  /* margin: 0 10px; */
}
.nav .nav-links a:hover {
  text-decoration: none;
}

.nav .nav-links .login-steam a {
  background-color: transparent;
  color: #333;
  border-color: #333;
  border: 1px solid;
}

.row {
  display: flex;
}
.btn {
  padding: 10px;
  border-radius: 5px;
  background-color: #333;
  border-color: #333;
  color: #fff;
  outline: 1px;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .nav {
    display: flex;
  }
  .container {
    padding: 0 0;
  }
  .row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
  }
  .logos {
    align-self: center;
  }
  .logos a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logos .pes-logo {
    width: 50%;
  }
  .logos .oq-logo {
    padding-left: 10px;
    width: 75%;
  }

  .text-right {
    padding: 0 0;
    width: 100%;
    float: middle;
    text-align: center;
    justify-items: center;
    padding-bottom: 5px;
  }
  .text-right a {
    float: none;
    margin-right: 15px;
  }
  .text-right h4 {
    float: none;
  }
}
</style>
