<template>
  <div class="footer">
    <div class="container">
      <div class="row w-full">
        <div class="logos">
          <router-link to="/">
            <img class="pes-logo w-full h-auto" src="../assets/img/pes-light.png" alt="PacificES Alt Logo"/>
            <img class="oq-logo w-full h-auto" src="../assets/img/pes-oq-negative.svg" alt="Oneqode Logo"/>
          </router-link>
        </div>
        <div class="col-md-3 text-right">
          <h4>&copy; 2023 PacificES</h4>
          <router-link to="/pricing"><span>Pricing</span></router-link>
          <router-link to="/faq"><span>FAQ</span></router-link>
          <a href="https://api.pacifices.cloud/docs.html" target="_blank">API</a>
          <router-link to="/tos"><span>Terms of Service</span></router-link>
          <router-link to="/privacypolicy"><span>Privacy Policy</span></router-link>
        </div>
      </div>
      <div class="text-center">
        <p><strong>5,000 servers deployed and counting...</strong></p>
        <a href="https://steamcommunity.com/groups/PacificES" target="_blank">Steam Group</a>
        <a href="https://www.oneqode.com/" target="_blank">Powered By OneQode</a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  height: 180px;
}
.footer {
  height: 225px;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #2e3234;
  padding: 50px;
}
.footer .container {
  width: 100%;
}
.footer hr {
  border-color: #333;
}
.footer .footer-logo h3 {
  color: #fff;
}
.footer .footer-links {
  text-align: right;
}
.footer .footer-links h4 {
  display: inline-block;
  margin-top: 25px;
  color: #fff;
}
.footer .footer-links .login-steam {
  margin-left: 30px;
  margin-top: 12px;
  display: inline-block;
}
.footer .footer-text p {
  color: #fff;
}
.footer .footer-text p a {
  color: #fff;
}
.footer .footer-text p .powered-by-steam {
  float: right;
}
.footer .footer-text p .dot-separator {
  color: #444;
}

.col-md-3 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 15%;
  float: left;
}
.logos {
  display: inline-block;
  width: 80%;
}
.logos .pes-logo {
  width: 20%;
  border-right-style: solid;
  border-right-color: #505050;
  border-right-width: 1px;
  padding-right: 10px;
}
.logos .oq-logo {
  padding-left: 1.5%;
  width: 30%;
  padding-bottom: 1%;
}
.footer a {
  color: #fff;
}

.footer h4 {
  color: #fff;
  margin-bottom: 15px;
}

.footer p {
  color: #fff;
}

.row {
  height: 100%;
  width: 70%;
}

.text-right {
  float: right;
}

.text-right a {
  font-size: 14px;
  display: inline-block;
  float: right;
  clear: right;
  text-decoration: none;
}

.text-right a:hover {
  text-decoration: none;
}

.text-right h4 {
  float: right;
  clear: right;
}

.text-center {
  text-align: center;
  font-size: 14px;
}

.text-center a {
  display: block;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .container {
    padding: 0 0;
  }
  .row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
  }
  .logos {
    align-self: center;
  }
  .logos a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logos .pes-logo {
    width: 50%;
  }
  .logos .oq-logo {
    padding-left: 10px;
    width: 75%;
  }

  .text-right {
    padding: 0 0;
    width: 100%;
    float: middle;
    text-align: center;
    justify-items: center;
    padding-bottom: 5px;
  }
  .text-right a {
    float: none;
    margin-left: 8px;
    margin-right: 8px;
  }
  .text-right h4 {
    float: none;
  }
}
</style>
