<template>
  <nav-component />
  <div class="header-holder">
    <h1 class="title">FAQ</h1>
  </div>

  <div class="container max-w-screen-md">
    <div class="pricing-holder py-12">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <h3>What is PacificES Cloud?</h3>
          <p>
            PacificES Cloud is a hosting platform for High-Performance Scrim, Practice and PUG CS:GO & CS2 servers in the Asia-Pacific and region. Servers are
            hosted on enterprise hardware and networks designed for zero downtime. We have created software designed for easy, hassle-free management of your
            server and account.
          </p>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-4">
          <h3>Where are your servers located?</h3>
          <p>
            Our servers are available in Sydney, Australia, Hong Kong, Guam and Tokyo to give you the best latencies wherever you are in the Asia-Pacific
            region.
          </p>

          <h3>How do I login to my panel?</h3>
          <p>
            To login, simply click the "Login with Steam" button located in the footer and header to be redirected to our panel for login. A valid Steam account
            is required to use this service.
          </p>

          <h3>How do I create a server?</h3>
          <p>
            To create a server, first log in to the panel and then find the button title "Create Server", clicking it will take you to the server creation page.
            Here you can choose the settings for your server, and at the bottom review the settings before creating the server. Due to our realtime deployment
            software, servers are created in less than 2 seconds.
          </p>

          <h3>How do I update my server?</h3>
          <p>
            CS server updates are automatically handled by the panel. When logging into the control panel, a notification will appear letting you know that a
            server may need to be updated. You will need to destroy that server and create a new one to get the latest CS:GO & CS2 version.
          </p>

          <h3>How do I add credit to my account?</h3>
          <p>
            To add credit to your account, access your account by clicking "Account" from the header. Navigate to the billing tab, and scroll down to the "Add
            Credit" section. Select the amount you intend to add to your account using the dropdown, and then either select PayPal or Xsolla for the payment
            method. Credit is applied immediately to your account.
          </p>

          <h3>What happens when I run out of credit?</h3>
          <p>
            When you run out of credit in your account, all of your running servers will be terminated. We will display notifications in the panel and send them
            via email to inform you that your account credit is low (less than $5 AUD).
          </p>

          <h3>How do I execute RCON commands on a server?</h3>
          <p>
            In the game panel you can send a command to the server through the console tab. In game, you can use the rcon password or the
            <kbd>!rcon</kbd> command in chat.
          </p>
        </div>
        <div class="col-md-4">
          <h3>How am I billed?</h3>
          <p>Each server is billed $0.95 AUD a day.</p>

          <h3>Is there a minimum amount of time my server can run for?</h3>
          <p>Yes, you will be charged for 1 day ($0.95 AUD) of usage if you start a server only for a few minutes.</p>

          <h3>Will I be billed whilst the server is off?</h3>
          <p>No - your IP and Port for the server will be lost however, and when you start the server up you will be issued with a new one.</p>

          <h3>Am I able to start multiple servers?</h3>
          <p>Yes, you may start multiple servers - however, your servers will halt if you do not have enough credit to sustain their use.</p>

          <h3>What forms of payment do you accept?</h3>
          <p>We accept Credit Cards through PayPal and Xsolla.</p>

          <h3>Can I get a refund?</h3>
          <p>No, we do not provide refunds.</p>

          <h3>How do I add mods?</h3>
          <p>
            Unfortunately due to security reasons, only mods hosted by us can be added to the server. The servers are designed to be as lightweight and high
            performance as possible and adding unnecessary mods can ruin performance.
          </p>

          <h3>How do I make a user an admin?</h3>
          <p>
            Head into the panel, go to your account and look for the "Admins" tab. Enter a user's steamID64 into the text box at the bottom of your page. You
            will have to destroy any running servers and recreate them to have the Admin copied across. The admin will then have access to <kbd>!admin</kbd>,
            <kbd>!rcon</kbd> and all of the commands that you have access to.
          </p>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </div>
  <footer-compotent />
</template>

<script>
import fontawesome from "@fortawesome/fontawesome";
import solid from "@fortawesome/fontawesome-free-solid";
import brands from "@fortawesome/fontawesome-free-brands";
import FooterCompotent from "./FooterCompotent.vue";
import NavComponent from "./NavComponent.vue";
fontawesome.library.add(brands, solid);

export default {
  name: "Faq",
  components: {
    FooterCompotent,
    NavComponent,
  },
};
</script>

<style scoped>
.row {
  position: center;
  display: flex;
  justify-content: center;
  /* margin-left: 15%;
  margin-right: 15%; */
}

.pricing-holder {
  /* margin: 50px; */
}

.col-md-8 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
}
.col-md-4 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
}
h3 {
  font-size: 24px;
}
</style>
